import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.less';
// import registerServiceWorker from './registerServiceWorker';
import { App } from './theme/components/App';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// registerServiceWorker();

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations: ServiceWorkerRegistration[]) => {
      for (const registration of registrations) {
        // Unregister a specific service worker if needed
        registration.unregister();
      }
    });
    window.location.href = "https://www.moose-offroad.com"; 
  }
  window.location.href = "https://www.moose-offroad.com"; 